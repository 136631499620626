import React from 'react';
import Lauriane from "../../../assets/LaurianeBG.png"
import "./presentation.css"

const Presentation = () => {
    return (
        <div className={"presentation"} id={"about"}>
            <div className={"presentation_bio"}>
                <h1>Korczak Lauriane</h1>
                <p>
                    <b>Architecte d’intérieur</b> créative et dévouée, diplômée de l'école <b>MJM Graphic Design</b> de
                    Bordeaux en
                    2024, je mets mon expertise au service de vos projets pour créer <b>des espaces uniques et
                    fonctionnels</b>, qui racontent avant tout votre histoire.</p>
                <h4>Mon approche</h4>
                <p>Je place la compréhension de <b>vos besoins et désirs</b> au cœur de ma démarche. Chaque projet est
                    l’opportunité d’élaborer un espace authentique, alliant fonctionnalité et esthétisme, en harmonie
                    avec <b>votre style de vie et vos aspirations</b>.</p>

                <h4>Ma façon de travailler</h4>
                <p>Impliquée et consciencieuse, je propose un <b>accompagnement complet et sur-mesure</b> :</p>
                <ul className={"presentation_bio_list"}>
                    <li>Croquis</li>
                    <li>Plans 2D</li>
                    <li>Vues 3D</li>
                    <li>Conseils personnalisés</li>
                    <li>Création de maquettes</li>
                    <li>Suivi de chantier</li>
                </ul>
                <p> Ensemble, nous identifierons les outils les plus adaptés pour concrétiser vos idées et donner vie à
                    vos espaces.</p>
                <h4>
                    Toujours en quête de nouveaux défis
                </h4>
                <p>
                    Si vous avez un projet en tête ou simplement une envie de renouveau, je serais ravie d’échanger avec
                    vous et de découvrir vos idées. Ensemble, nous créerons <b>un espace qui vous ressemble</b>, et où chaque
                    détail compte.
                </p>
            </div>
            <div className={"presentation_photo"}>
                <img src={Lauriane} className={"presentation_photo_img"} alt={"Lauriane Korczak"}/>
            </div>
        </div>
    );
};

export default Presentation;