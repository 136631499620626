import React, {useRef, useState} from 'react';
import logo from "../../assets/Logo.png";
import {FaInstagram, FaLinkedin} from "react-icons/fa";
import {Link} from "react-router-dom";
import emailjs from "@emailjs/browser";
import "./contact.css"
import Navbar from "../navbar/Navbar";

const Contact = () => {

    const form = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("")
    const [messageBG, setMessageBG] = useState("")
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;


    const sendEmail = (e) => {
        e.preventDefault();


        const email = document.getElementById("email")


        if (regex.test(email.value)) {
            emailjs
                .sendForm(`${process.env.REACT_APP_SERVICE_EMAIL_ID}`, `${process.env.REACT_APP_SERVICE_TEMPLATE_ID}`, form.current, {
                    publicKey: `${process.env.REACT_APP_SERVICE_PUBLIC_KEY}`,
                })
                .then(
                    () => {
                        console.log('SUCCESS!');
                        setIsOpen(true)
                        setMessageBG("green")
                        setMessage("Votre email a bien été envoyer")
                    },
                    (error) => {
                        console.log('FAILED...', error.text);
                        setIsOpen(true)
                        setMessageBG("red")

                        setMessage("Une erreur est survenue réessayer plus tard, où contactez-moi par téléphone")


                    },
                );
        } else {
            setIsOpen(true)
            setMessageBG("red")
            setMessage("Votre adresse email est incorrect")
        }

    };
    return (
        <div>
         <Navbar props={"white"} bg={"#333333"}/>
            <div className={"contact_content"}>
                <div className={"contact_right_second_rs"}>
                    <h2 className={"contact_right_second_title"}>Réseaux Sociaux</h2>
                    <div className={"contact_right_rs_content"}>
                        <div className={"contact_right_second_rs_content"}>
                            <FaLinkedin className={"rs-icon"}/>
                            <Link target={"_blank"}
                                  to="https://www.linkedin.com/in/lauriane-korczak-735940181/">Linkedin</Link>
                        </div>
                        <div className={"contact_right_second_rs_content"}>
                            <FaInstagram className={"rs-icon"}/>
                            <Link target={"_blank"}
                                  to={"https://www.instagram.com/k_architecture_l/?hl=fr"}>Instagram</Link>
                        </div>

                    </div>

                </div>
                <div className={"contact_left"}>
                    <h2 className={"contact_title"}>Contactez-moi</h2>
                    <div className={`popup ${isOpen ? 'open' : ''}`} style={{backgroundColor: `${messageBG}`}}>
                        <p className="popup-message">{message}</p>
                    </div>
                    <form ref={form} onSubmit={sendEmail}>
                        <label>Nom:</label>
                        <input type="text" name="user_name" required={true}/>
                        <label>Email:</label>
                        <input id={"email"} type="email" name="user_email" required={true}/>
                        <label>Message:</label>
                        <textarea name="message" required={true}></textarea>
                        <input type="submit" value="Envoyer"/>
                    </form>
                </div>

            </div>

        </div>
    );
}

export default Contact;