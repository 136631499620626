import React from 'react';
import "./projets.css"
import Navbar from "../navbar/Navbar";
import archi from "../../assets/slide1.png"
import interieur2 from "../../assets/slide2.png"
import interieur3 from "../../assets/IMG_4124.jpeg"
import {FaArrowCircleRight} from "react-icons/fa";
import {Link} from "react-router-dom";


const Projets = () => {


    const allProjects = [
        {
            image: archi,
            name: "Séjour d'inspiration Japandi"
        },
        {
            image: interieur2,
            name: "Ambiance Paysagère autour d'une Piscine sur le Bassin d'Arcachon",
        },
        {
            image: interieur3,
            name: "L'Éveil de Nezuko"
        }
    ]


    return (
        <div>

            <div className={"projets"}>
                <Navbar props={"white"} bg={"#333333"}/>

                <div className={"projets_container"}>
                    <h1 className={"projets_container_title"}>Mes projets</h1>
                    {allProjects.map((m, index) => (
                        <div key={index} className={"projets_box"}
                             style={{backgroundImage: `url(${m.image})`, backgroundPosition: "center"}}>
                            <div className={"projets_box_content"}>
                                <h3>{m.name}</h3>
                                <Link to={`/projets/${index}`} className={"projets_bouton"}>
                                    <p>Voir en détails</p>
                                    <FaArrowCircleRight className={"arrow_icon"}/>
                                </Link>
                            </div>
                        </div>
                    ))}


                </div>
            </div>
        </div>
    )
        ;
};

export default Projets;